<template>
    <div class="yx_7525">
        <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
            <img @click="goback" src="../../../../static/common/logo.png" alt="" />
        </div>
        <div class="step_1">
            <div class="w">
                <!-- <div class="text">
                    <p>听公开课 盈利更有把握</p>
                    <p>公开课时间： 7月26日20:00-22:00</p>
                </div> -->
            </div>
        </div>
        <div class="main">
            <div class="w">
                <div class="step_2">
                    <div class="title">
                        <p>议息关键解读</p>
                        <p>下次美联储利率公布时间：2023年7月27日02:00</p>
                    </div>
                    <div class="box">
                        <div class="item">
                            <div class="question">· 聚焦美联储议息：</div>
                            <div class="answer">
                                <p>美联储议息会议是（FOMC），主要内容是透过调控当下的货币政策，来达到经济成长及物价稳定两者间的平衡。</p>
                                <p>美联储6月利率决议，美联储维持利率不变，至5.00-5.25%不变，符合预期。美联储暂停加息，自2022年3月以来的连续10次加息暂告一段落。此外，美联储利率掉期不再押注美联储将在2023年降息。美联储利率掉期押注9月份利率峰值达到5.35%
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="question">· 如何引爆交易机会？</div>
                            <div class="answer">
                                <p><span>行情动荡引发交易机会</span></p>
                                <p>美元主导着全球金融交易市场，七月公布议息结果后，引发期货，股市，债市波动，给投资者创造了良好交易的机会，投资者可以根据加息减息来判断市场美元流动性，有迹可循的进行投资交易。
                                </p>
                                <p>在美联储在决议公布后，现货金价短线迅速下挫10余美元。美元指数短线拉升50点，其它非美品种不同程度下挫。美国两年期国债收益率短线走高14个基点；美国十年期国债收益率短线走高7个基点；美股三大股指短线下跌。
                                </p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="question">· 选择什么交易产品？</div>
                            <div class="answer">
                                <p><span>用美元定价和结算的产品</span></p>
                                <p>首先，议息会议决定了美元的升值或贬值，这直接反应了与美元相对应货币的价值波动</p>
                                <p>例如：美元/日元，美元加息，美元流动性减少，美元相对于日元升值，日元贬值</p>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="item">美国原油</div>
                        <div class="item">美元/日元</div>
                        <div class="item">欧元/美元</div>
                        <div class="item">英镑/美元</div>
                        <div class="item">纳斯达克指数</div>
                        <div class="item">标普500指数</div>
                        <div class="item">道琼斯指数</div>
                    </div>
                    <div class="btnBox">
                        <div @click="getLive800">咨询客服</div>
                        <div @click="getClassRoom">进入直播间</div>
                    </div>
                </div>
                <div class="step_3">
                    <div class="title">
                        <p>交易赚美元</p>
                        <p><span @click="controlPop = true">活动规则</span></p>
                    </div>
                    <div class="imgBox">
                        <img src="/subject/yx_7525/index/s5.png" alt="">
                        <img src="/subject/yx_7525/index/s6.png" alt="">
                        <img src="/subject/yx_7525/index/s7.png" alt="">
                    </div>
                    <div class="brandBox">
                        <div class="item">
                            <p>超低门槛</p>
                            <p>0.1手起领</p>
                        </div>
                        <div class="item">
                            <p>豪礼加持</p>
                            <p>660美元直送</p>
                        </div>
                        <div class="item">
                            <p>超多选择</p>
                            <p>各品种任你选</p>
                        </div>
                    </div>
                    <div class="textBox">
                        <p>所有产品皆与美元涨跌息息相关</p>
                        <p>
                            <img src="/subject/yx_7525/index/s10.png" alt="">
                        </p>
                        <p>美元现金 ≈ 交易手数的<span>4倍!</span></p>
                    </div>
                    <div class="btnBox">
                        <div @click="getLive800">咨询客服</div>
                        <div @click="getClassRoom">进入直播间</div>
                    </div>
                </div>
                <div class="step_4">
                    <div class="title">
                        <p>决战议息之夜</p>
                        <p>万良老师为您指点交易迷津 助您把握赚钱良机</p>
                    </div>
                    <div class="box">
                        <div class="left">
                            <div class="textBox">
                                <div class="item">
                                    <div>时间</div>
                                    <div>7月26日20:00-22:00</div>
                                </div>
                                <div class="item">
                                    <div>地点</div>
                                    <div>红狮课堂-公开课</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <p>福利加持</p>
                                <p>直播公开课期间，点击<span>万良老师发出的个人链接</span></p>
                                <p>开户成功并激活，<span>即可免除2000入金要求</span></p>
                                <p>直接升级进入<span>Svip房间</span>，享专家专属服务!</p>
                            </div>
                        </div>
                        <div class="right">
                            <p>· 透露一点 ·</p>
                            <div>
                                1.解读议息 投资有道<br>
                                2.案例实操 一学就会<br>
                                3.专家交易心路大公开<br>
                                4.加入名师战队 私享盈利先机<br>
                                5.现场实盘行情 即刻带你抓住
                            </div>
                            <p>纯纯干货免费学!</p>
                            <p>上半年财富决胜的关键!</p>
                        </div>
                    </div>
                    <div class="imgBox">
                        <img src="/subject/yx_7525/index/s11.png" alt="">
                    </div>
                    <div class="btnBox">
                        <div @click="getLive800">预约公开课</div>
                        <div @click="getClassRoom">进入直播间</div>
                    </div>
                </div>
                <div class="step_5">
                    <div class="title">
                        <p>交易近距离</p>
                        <p>直击交易现场 近距离接触赚钱的机会</p>
                    </div>
                    <div class="box">
                        <div class="left">
                            <div class="textBox">
                                <p>咨询客服下载官方APP</p>
                                <P>发现更多智富可能</P>
                            </div>
                            <div class="btnBox">
                                <div @click="getLive800">预约公开课</div>
                                <div @click="getClassRoom">进入直播间</div>
                            </div>
                        </div>
                        <div class="right">
                            <img src="/subject/yx_7525/index/s8.png" alt="">
                            <img src="/subject/yx_7525/index/s9.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup" v-if="controlPop">
            <span @click="controlPop = false" class="guanbi1">X</span>
            <ul class="popContent">
                <li>
                    <span>活动对象：</span>
                    本公司所有新老用户
                </li>
                <li>
                    <span>活动时间：</span>
                  2023年7月24日06:00 —— 2023年7月29日06:00
                </li>
                <li>
                    <span>兑奖截止时间：</span>
                   2023年8月25日00:00（逾期失效）
                </li>
                <li>交易送金，升降任你赚！交易越多赚越多，最高领660美元现金！</li>
                <li>
                    <span>活动规则：</span><br />
                    活动期间内，凡在本公司的APP或MT4交易平台交易指定品种，完成指定交易手数，即可兑换相应美元现金奖励，交易时间结束后，统一开始兑换，限兑奖一次；
                </li>
                <li>
                    <span>活动参与品种：</span><br />
                    美国原油、 美元/日元、 欧元/美元、英镑/美元、 纳斯达克指数、 标普500指数、 道琼斯指数
                </li>
                <li>
                    <span>交易奖励：</span><br />
                    <table>
                        <tr>
                            <th>交易品种</th>
                            <th>赠送条件</th>
                            <th>美元现金奖励</th>
                        </tr>
                        <tr>
                            <th rowspan="7">
                                <p>美国原油</p>
                                <p>美元/日元</p>
                                <p>欧元/美元</p>
                                <p>英镑/美元</p>
                                <p>纳斯达克指数</p>
                                <p>标普500指数</p>
                                <p>道琼斯指数</p>
                            </th>
                            <th>≥0.1手</th>
                            <th>0.3美元</th>
                        </tr>
                        <tr>
                            <th>≥1手</th>
                            <th>3美元</th>
                        </tr>
                        <tr>
                            <th>≥6手</th>
                            <th>20美元</th>
                        </tr>
                        <tr>
                            <th>≥16手</th>
                            <th>50美元</th>
                        </tr>
                        <tr>
                            <th>≥36手</th>
                            <th>120美元</th>
                        </tr>
                        <tr>
                            <th>≥66手</th>
                            <th>260美元</th>
                        </tr>
                        <tr>
                            <th>≥166手</th>
                            <th>660美元</th>
                        </tr>
                    </table>
                </li>
                <li>
                    <span>领取方式：</span><br />
                    1.议息奖励在“APP”→“我的”→“活动中心”→“议息活动”中领取； <br />
                    2.活动只计算活动时间内的交易手数，奖励会在活动结束后开启兑换； <br />
                    3.奖励后的美元可直接提现，亦可继续用于交易；<br />
                    4.美元现金奖励需在兑奖截止日期前领取，逾期失效；
                </li>
                <li>
                    <span>注意事项</span><br />
                    1.客户参与活动需在本公司开立真实交易账户；<br />
                    2.本活动仅限于活动指定品种的交易手数，手数计算以开平仓为准；<br />
                    3.各账户层级用户均可参加本活动，美元现金奖励将依据活动交易时间结束后的最终平仓手数发放；<br />
                    4.凡推广商及其名下客户不参与本活动；<br />
                    5.本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
                </li>
            </ul>
        </div>
        <div class="window-mask" v-if="controlPop"></div>
    </div>
</template>
<script>
import { getYiXiTime } from "../../../../api/subject/yx_7525";
import { parseTime } from "../../../../utils/tools"
export default {
    name: "yx_7525",
    data() {
        return {
            controlPop: false,
            activityTime: {
                starttime: null,
                endtime: null,
                prizedeadline: null,
            },
            navBarFixed: false
        };
    },
    created() {
        this.$emit("controlShow", true);
        if (document.body.clientWidth < 500) {
            this.$router.push({
                name: "yx_7525_wap",
            });
        }
        // 获取 活动 时间
        var data = {
            Platform: '1',
            ActivityId: '261',
            Account: undefined
        }
        getYiXiTime(JSON.stringify(data)).then(res => {
            this.activityTime.starttime = parseTime((new Date(res.content.starttime + 28800000)), 'yyyy-MM-dd hh:mm:ss')
            this.activityTime.endtime = parseTime((new Date(res.content.endtime + 28800000)), 'yyyy-MM-dd hh:mm:ss');
            this.activityTime.prizedeadline = parseTime((new Date(res.content.prizedeadline + 28800000)), 'yyyy-MM-dd hh:mm:ss');
        })
    },
    methods: {
        goback() {
            window.location.href = window.location.origin;
        },
        getLive800() {
            window.open(
                this.$parent.live800UrlUc
            );
        },
        getClassRoom() {
            this.$router.push({
                name: "classRoom",
            });
        },
        getDownloadApp() {
            window.open("https://oss.0790jiaxiao.com/HS/software/hs_9999.apk")
        }
    },
};
</script>
  
<style lang="less" scoped>
.tabbar {
    width: 100%;
    background-color: #ffffff;

    img {
        margin: 20px 50px;
        width: 130px;
    }
}

.yx_7525 {
    width: 100%;
    background-color: #040d57;
}

.w {
    width: 1200px;
    margin: 0 auto;
}

.step_1 {
    width: 100%;
    height: 900px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 490px;
    background: url("/subject/yx_7525/index/s1.png") no-repeat center;
    background-size: cover;
    position: relative;
    z-index: 9;

    .text {
        width: 380px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
    }
}

.main {
    width: 100%;
    height: 3300px;
    background: url("/subject/yx_7525/index/s2.png") no-repeat center;
    background-size: cover;
}

.step_2 {
    width: 100%;
    height: 930px;
    box-sizing: border-box;
    background: linear-gradient(to top, #dae3ff, #ffffff);
    border-radius: 20px;
    margin-top: -280px;
    position: relative;
    z-index: 23;

    .title {
        height: 145px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 42px;

        p {
            &:nth-child(1) {
                background-image: -webkit-linear-gradient(top, #ff9e9e, #eb4141);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
            }

            &:nth-child(2) {
                color: #334d95;
                font-size: 16px;
            }
        }
    }

    .box {
        width: 100%;
        box-sizing: border-box;
        padding: 0 40px;

        .item {
            margin-top: 16px;

            .question {
                color: #334d95;
                font-size: 24px;
            }

            .answer {
                width: 100%;
                box-sizing: border-box;
                padding: 13px 16px;
                color: #334d95;
                font-size: 15px;
                line-height: 20px;
                background-color: #dceaff;
                border: 1px solid #334d95;
                border-radius: 10px;
                margin-top: 12px;

                span {
                    font-weight: 600;
                }
            }
        }
    }

    .bottom {
        width: 700px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 15px;

        .item {
            font-size: 17px;
            border-radius: 10px;
            background: linear-gradient(to top, #f9e7cd, #ffffff);
            padding: 16px 20px;
            color: #a5542e;
            margin: 10px 15px;

            &:nth-child(2n) {
                background: linear-gradient(to top, #c1cfff, #ffffff);
                color: #334d95;
            }
        }
    }

    .btnBox {
        width: 493px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 25px;

        div {
            width: 216px;
            height: 65px;
            border-radius: 10px;
            text-align: center;
            line-height: 65px;
            cursor: pointer;
            font-size: 24px;
            font-weight: 600;
            background: linear-gradient(to top, #c1cfff, #ffffff);
            color: #334d95;

            &:nth-child(2) {
                background: linear-gradient(to top, #416ade, #9fa9ff);
                color: #fff;
            }
        }
    }
}

.step_3 {
    width: 100%;
    height: 845px;
    box-sizing: border-box;
    background: linear-gradient(to top, #dae3ff, #ffffff);
    border-radius: 20px;
    margin-top: 30px;
    padding: 0 172px;

    .title {
        height: 145px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 42px;

        p {
            &:nth-child(1) {
                background-image: -webkit-linear-gradient(top, #ff9e9e, #eb4141);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
            }

            &:nth-child(2) {
                color: #334d95;
                font-size: 16px;
                text-align: center;

                span {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    .imgBox {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-top: 24px;

        img {
            height: 290px;

            &:nth-child(2) {
                height: 317px;
            }
        }
    }

    .brandBox {
        width: 789px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 20px;

        .item {
            width: 180px;
            height: 90px;
            text-align: center;
            border: 1px solid #a0aaff;
            font-size: 15px;
            background-color: #fff;
            color: #334d95;
            box-sizing: border-box;
            padding-top: 15px;
            border-radius: 10px;

            p {
                &:nth-child(1) {
                    font-weight: 600;
                    margin-bottom: 11px;
                }
            }
        }
    }

    .textBox {
        margin-top: 20px;
        text-align: center;
        color: #334d95;
        font-size: 17px;

        p {
            &:nth-child(1) {
                margin-bottom: 12px;
            }

            &:nth-child(3) {
                margin-top: -12px;
            }
        }

        img {
            height: 23px;
            display: inline-block;
            position: relative;
            left: -20px;
        }

        span {
            color: #ff5a3d;
            font-weight: 600;
            font-size: 24px;
        }
    }

    .btnBox {
        width: 493px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 25px;

        div {
            width: 216px;
            height: 65px;
            border-radius: 10px;
            text-align: center;
            line-height: 65px;
            cursor: pointer;
            font-size: 24px;
            font-weight: 600;
            background: linear-gradient(to top, #c1cfff, #ffffff);
            color: #334d95;

            &:nth-child(2) {
                background: linear-gradient(to top, #416ade, #9fa9ff);
                color: #fff;
            }
        }
    }

}

.step_4 {
    width: 100%;
    height: 650px;
    box-sizing: border-box;
    background: linear-gradient(to top, #dae3ff, #ffffff);
    border-radius: 20px;
    margin-top: 20px;
    padding: 0 155px;

    .title {
        height: 145px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 42px;

        p {
            &:nth-child(1) {
                background-image: -webkit-linear-gradient(top, #ff9e9e, #eb4141);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
            }

            &:nth-child(2) {
                color: #334d95;
                font-size: 24px;
                font-weight: 600;
            }
        }
    }

    .box {
        height: 330px;
        margin-top: 20px;

        .left {
            float: left;
            width: 510px;

            .textBox {
                width: 100%;

                .item {
                    width: 100%;
                    height: 50px;
                    border-radius: 10px;
                    border: 1px solid #a0aaff;
                    line-height: 50px;
                    display: flex;
                    justify-content: space-between;
                    color: #334d95;
                    margin-top: 10px;

                    div {
                        &:nth-child(1) {
                            width: 118px;
                            font-size: 17px;
                            text-align: center;
                            background-color: #dceaff;
                            border-radius: 10px;
                            border-right: 1px solid #a0aaff;
                        }

                        &:nth-child(2) {
                            width: 450px;
                            text-align: center;
                        }
                    }
                }
            }

            .bottom {
                margin-top: 38px;
                width: 100%;
                text-align: center;

                p {
                    font-size: 17px;
                    color: #334d95;
                    margin-bottom: 14px;

                    &:nth-child(1) {
                        font-size: 24px;
                        font-weight: 600;
                    }

                    span {
                        font-size: 21px;
                        font-weight: 600;
                        color: #ff5a3d;

                    }
                }
            }
        }

        .right {
            float: left;
            width: 320px;
            margin-left: 60px;
            text-align: center;
            color: #334d95;

            p {
                font-size: 21px;
                margin-top: 14px;

                &:nth-child(1) {
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    margin-top: 0;
                }
            }

            div {
                width: 100%;
                font-size: 17px;
                text-align: center;
                background-color: #fff;
                border-radius: 10px;
                border: 1px solid #a0aaff;
                box-sizing: border-box;
                line-height: 26px;
                padding: 15px 0;
            }
        }
    }

    .imgBox {
        text-align: center;
        margin-top: 15px;

        img {
            height: 23px;
            position: relative;
            left: -45px;
        }
    }

    .btnBox {
        width: 493px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: -15px;

        div {
            width: 216px;
            height: 65px;
            border-radius: 10px;
            text-align: center;
            line-height: 65px;
            cursor: pointer;
            font-size: 24px;
            font-weight: 600;
            background: linear-gradient(to top, #c1cfff, #ffffff);
            color: #334d95;

            &:nth-child(2) {
                background: linear-gradient(to top, #416ade, #9fa9ff);
                color: #fff;
            }
        }
    }

}

.step_5 {
    width: 100%;
    height: 730px;
    box-sizing: border-box;
    background: linear-gradient(to top, #dae3ff, #ffffff);
    border-radius: 20px;
    margin-top: 20px;
    padding: 0 200px;

    .title {
        height: 145px;
        box-sizing: border-box;
        text-align: center;
        padding-top: 42px;

        p {
            &:nth-child(1) {
                background-image: -webkit-linear-gradient(top, #ff9e9e, #eb4141);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                font-size: 48px;
                margin-bottom: 20px;
            }

            &:nth-child(2) {
                color: #334d95;
                font-size: 24px;
                font-weight: 600;
            }
        }
    }

    .box {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .left {
            text-align: center;
            padding-top: 80px;

            .textBox {
                font-size: 17px;
                color: #334d95;
                line-height: 28px;
            }

            .btnBox {
                width: 100%;
                margin: 0 auto;
                margin-top: 90px;

                div {
                    width: 216px;
                    height: 65px;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 65px;
                    cursor: pointer;
                    font-size: 24px;
                    font-weight: 600;
                    background: linear-gradient(to top, #c1cfff, #ffffff);
                    color: #334d95;
                    margin-bottom: 40px;

                    &:nth-child(2) {
                        background: linear-gradient(to top, #416ade, #9fa9ff);
                        color: #fff;
                    }
                }
            }

        }

        .right {
            width: 483px;
            float: right;
            display: flex;
            justify-content: space-between;

            img {
                width: 233px;
            }
        }
    }
}

.window-mask {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.75;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
}

.popup {
    overflow-x: hidden;
    width: 1100px;
    height: 800px;
    border-radius: 20px;
    background: #fdf5e6;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%; -50%);
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);
    overflow-y: scroll;
    padding-bottom: 40px;
    z-index: 99999999999999;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }



    .guanbi1 {
        float: right;
        cursor: pointer;
        margin-top: 8px;
        margin-right: 10px;
    }

    .popContent {
        box-sizing: border-box;
        padding: 40px 50px 20px;

        li {
            font-size: 18px;
            color: #a86e4f;
            line-height: 36px;

            span {
                font-weight: 600;
                color: #e34a3f;
            }

            img {
                width: 100%;
                height: auto;
                margin: 20px 0;
            }

            table {
                border: 1px solid #e34a3f;
                border-radius: 15px;
                width: 100%;

                tr {
                    th {
                        text-align: center;
                        font-weight: 500;
                        line-height: 50px;
                        border-bottom: 1px solid #d2a765;
                        border-right: 1px solid #d2a765;
                        padding: 5px;
                    }

                    th:last-child {
                        border-right: 1px solid transparent;
                    }
                }

                tr:nth-child(1) {
                    th {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
</style>